:root {
  --main-50: #e4e4e4;
  --main-100: #bbbbbb;
  --main-200: #8e8e8e;
  --main-300: #606060;
  --main-400: #3e3e3e;
  --main-500: #1c1c1c;
  --main-600: #181818;
  --main-700: #141414;
  --main-800: #0f0f0f;
  --main-900: #070707;
  --main-A100: #c75656;
  --main-A200: #524343;
  --main-A400: #241414;
  --main-A700: #110b0b;
  --main-contrast-50: rgba(0, 0, 0, 0.87);
  --main-contrast-100: rgba(0, 0, 0, 0.87);
  --main-contrast-200: rgba(0, 0, 0, 0.87);
  --main-contrast-300: rgb(255, 255, 255);
  --main-contrast-400: rgb(255, 255, 255);
  --main-contrast-500: rgb(255, 255, 255);
  --main-contrast-600: rgb(255, 255, 255);
  --main-contrast-700: rgb(255, 255, 255);
  --main-contrast-800: rgb(255, 255, 255);
  --main-contrast-900: rgb(255, 255, 255);
  --main-contrast-A100: rgb(255, 255, 255);
  --main-contrast-A200: rgb(255, 255, 255);
  --main-contrast-A400: rgb(255, 255, 255);
  --main-contrast-A700: rgb(255, 255, 255);
  --primary-50: #eaeeff;
  --primary-100: #ccd4fe;
  --primary-200: #aab7fd;
  --primary-300: #879afc;
  --primary-400: #6e84fc;
  --primary-500: #546efb;
  --primary-600: #4963eb;
  --primary-700: #3c54d6;
  --primary-800: #2f46c2;
  --primary-900: #172c9f;
  --primary-A100: #a8b6ff;
  --primary-A200: #8597ff;
  --primary-A400: #506bff;
  --primary-A700: #1532d5;
  --primary-contrast-50: rgba(0, 0, 0, 0.87);
  --primary-contrast-100: rgba(0, 0, 0, 0.87);
  --primary-contrast-200: rgba(0, 0, 0, 0.87);
  --primary-contrast-300: rgba(0, 0, 0, 0.87);
  --primary-contrast-400: rgba(0, 0, 0, 0.87);
  --primary-contrast-500: rgb(255, 255, 255);
  --primary-contrast-600: rgb(255, 255, 255);
  --primary-contrast-700: rgb(255, 255, 255);
  --primary-contrast-800: rgb(255, 255, 255);
  --primary-contrast-900: rgb(255, 255, 255);
  --primary-contrast-A100: rgba(0, 0, 0, 0.87);
  --primary-contrast-A200: rgba(0, 0, 0, 0.87);
  --primary-contrast-A400: rgb(255, 255, 255);
  --primary-contrast-A700: rgb(255, 255, 255);
  --accent-50: #e0faf3;
  --accent-100: #b3f3e2;
  --accent-200: #80eccf;
  --accent-300: #4de4bc;
  --accent-400: #26dead;
  --accent-500: #00d89f;
  --accent-600: #00bd8c;
  --accent-700: #009b72;
  --accent-800: #007a5a;
  --accent-900: #003f2f;
  --accent-A100: #6dffd8;
  --accent-A200: #31edbb;
  --accent-A400: #00d89f;
  --accent-A700: #007254;
  --accent-contrast-50: rgba(0, 0, 0, 0.87);
  --accent-contrast-100: rgba(0, 0, 0, 0.87);
  --accent-contrast-200: rgba(0, 0, 0, 0.87);
  --accent-contrast-300: rgba(0, 0, 0, 0.87);
  --accent-contrast-400: rgba(0, 0, 0, 0.87);
  --accent-contrast-500: rgba(0, 0, 0, 0.87);
  --accent-contrast-600: rgb(255, 255, 255);
  --accent-contrast-700: rgb(255, 255, 255);
  --accent-contrast-800: rgb(255, 255, 255);
  --accent-contrast-900: rgb(255, 255, 255);
  --accent-contrast-A100: rgba(0, 0, 0, 0.87);
  --accent-contrast-A200: rgba(0, 0, 0, 0.87);
  --accent-contrast-A400: rgba(0, 0, 0, 0.87);
  --accent-contrast-A700: rgb(255, 255, 255);
  --grey-50: rgba(254, 254, 254, 0.8896);
  --grey-100: rgba(251, 251, 251, 0.724);
  --grey-200: rgba(245, 245, 245, 0.54);
  --grey-300: rgba(232, 232, 232, 0.356);
  --grey-400: rgba(206, 206, 206, 0.218);
  --grey-500: rgba(0, 0, 0, 0.08);
  --grey-600: rgba(0, 0, 0, 0.08);
  --grey-700: rgba(0, 0, 0, 0.08);
  --grey-800: rgba(0, 0, 0, 0.08);
  --grey-900: rgba(0, 0, 0, 0.08);
  --grey-A100: rgba(250, 240, 240, 0.54);
  --grey-A200: rgba(222, 215, 215, 0.218);
  --grey-A400: rgba(0, 0, 0, 0.08);
  --grey-A700: rgba(0, 0, 0, 0.08);
  --grey-contrast-50: rgba(0, 0, 0, 0.87);
  --grey-contrast-100: rgba(0, 0, 0, 0.87);
  --grey-contrast-200: rgba(0, 0, 0, 0.87);
  --grey-contrast-300: rgba(0, 0, 0, 0.87);
  --grey-contrast-400: rgba(0, 0, 0, 0.87);
  --grey-contrast-500: rgba(0, 0, 0, 0.87);
  --grey-contrast-600: rgba(0, 0, 0, 0.87);
  --grey-contrast-700: rgba(0, 0, 0, 0.87);
  --grey-contrast-800: rgba(0, 0, 0, 0.87);
  --grey-contrast-900: rgba(0, 0, 0, 0.87);
  --grey-contrast-A100: rgba(0, 0, 0, 0.87);
  --grey-contrast-A200: rgba(0, 0, 0, 0.87);
  --grey-contrast-A400: rgba(0, 0, 0, 0.87);
  --grey-contrast-A700: rgba(0, 0, 0, 0.87);
  --warn-50: #ffe7e7;
  --warn-100: #ffc4c4;
  --warn-200: #ff9c9c;
  --warn-300: #ff7474;
  --warn-400: #ff5757;
  --warn-500: #ff3939;
  --warn-600: #ec3232;
  --warn-700: #d32828;
  --warn-800: #bb1f1f;
  --warn-900: #910e0e;
  --warn-A100: #ff9c9c;
  --warn-A200: #ff7171;
  --warn-A400: #ff3939;
  --warn-A700: #cb0808;
  --warn-contrast-50: rgba(0, 0, 0, 0.87);
  --warn-contrast-100: rgba(0, 0, 0, 0.87);
  --warn-contrast-200: rgba(0, 0, 0, 0.87);
  --warn-contrast-300: rgba(0, 0, 0, 0.87);
  --warn-contrast-400: rgba(0, 0, 0, 0.87);
  --warn-contrast-500: rgb(255, 255, 255);
  --warn-contrast-600: rgb(255, 255, 255);
  --warn-contrast-700: rgb(255, 255, 255);
  --warn-contrast-800: rgb(255, 255, 255);
  --warn-contrast-900: rgb(255, 255, 255);
  --warn-contrast-A100: rgba(0, 0, 0, 0.87);
  --warn-contrast-A200: rgba(0, 0, 0, 0.87);
  --warn-contrast-A400: rgb(255, 255, 255);
  --warn-contrast-A700: rgb(255, 255, 255);
}